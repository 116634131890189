.Plexiglass .AlertBox.PlexiglassBox .AlertBox-content {
  max-width: 100% !important;
}
.Plexiglass_Content {
  overflow-y: auto;
}
.Plexiglass .Template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}
.Plexiglass .Template_Header {
  color: #fff;
  /*height: -moz-max-content;*/
  height: max-content;
}
.Plexiglass .Template_Header_Title {
  display: flex;
  justify-content: center;
}
.Plexiglass .Template_Header_Title img {
  width: 28mm;
}
.Plexiglass .Template_Header_Title .text {
  font-size: 1.8rem;
  max-width: 33%;
  text-align: center;
  font-weight: bolder;
}
.Plexiglass .Template_Header_Description {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
}
.Plexiglass .Template_Header_QR {
  width: 100%;
  height: 25.5mm;
  display: flex;
  justify-content: center;
}
.Plexiglass .Template_Header_QR .QrCode {
  width: 50mm;
  height: 50mm;
  background: #fff;
  border-radius: 9px;
  padding: 2mm;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebebee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Plexiglass .Template_Header_QR .QrCode img {
  width: 53mm;
}
.Plexiglass .Template_Content,
.Plexiglass .Template_Footer {
  background: #f7f7f7;
}
.Plexiglass .Template_Content {
  height: 100%;
  margin-top: 52.5mm;
  width: 100%;
  position: relative;
}
.Plexiglass .Template_Content_Image {
  width: 40mm;
  height: 40mm;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 3.3mm 2mm;
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: -20mm;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  border: 0.5px solid #ebebee;
}
.Plexiglass .Template_Content_Image .icon {
  width: 90%;
  height: auto;
}
.Plexiglass .Template_Content_Text {
  margin-top: 20mm;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Plexiglass .Template_Content_Text .Title {
  color: #001720;
  font-size: 1.2rem;
  font-weight: 700;
  position: relative;
  /*width: -moz-max-content;*/
  width: max-content;
  line-height: 38px;
  margin: 2mm;
}
.Plexiglass .Template_Content_Text .Title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #ffbb6d;
}
.Plexiglass .Template_Content_Text .Text {
  text-align: center;
  max-width: 33%;
  font-size: 0.6rem;
  color: #94999c;
}
.Plexiglass .Template_Footer {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.Plexiglass .Template_Footer .icon {
  width: auto;
  height: 9mm;
}
